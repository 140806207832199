import React, { FC, HTMLAttributes, RefCallback, RefObject } from 'react';
// @ts-ignore
import BrowserSymbol from 'svg-baker-runtime/browser-symbol';
// @ts-ignore
import { assign } from 'es6-object-assign';
import { addSpriteSymbol, useIsomorphicLayoutEffect } from '../sprite';
import { SvgIcon } from '../SvgIcon';

const viewBox = '0 0 24 24';
const id = 'spinner_24';
const content = '<symbol xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" id="spinner_24"><path d="M12 24a1.25 1.25 0 110-2.5A9.5 9.5 0 102.5 12c0 1.1.187 2.175.548 3.188a1.25 1.25 0 01-2.355.84A11.981 11.981 0 010 12C0 5.373 5.373 0 12 0s12 5.373 12 12-5.373 12-12 12z" fill="currentColor" fill-rule="nonzero" /></symbol>';

let isMounted = false;
function mountIcon() {
  if (!isMounted) {
    addSpriteSymbol(new BrowserSymbol({
      id: id,
      viewBox: viewBox,
      content: content,
    }));

    isMounted = true;
  }
}

export interface Icon24SpinnerProps extends HTMLAttributes<HTMLDivElement> {
  fill?: string;
  width?: number;
  height?: number;
  getRootRef?: RefCallback<HTMLDivElement> | RefObject<HTMLDivElement>;
}

const Icon24Spinner: FC<Icon24SpinnerProps> = (props) => {
  useIsomorphicLayoutEffect(() => {
    mountIcon();
  }, []);

  return React.createElement(SvgIcon, assign({}, props, {
    viewBox: viewBox,
    id: id,
    width: !isNaN(props.width) ? +props.width : 24,
    height: !isNaN(props.height) ? +props.height : 24,
  }));
};

(Icon24Spinner as any).mountIcon = mountIcon;

export default Icon24Spinner;
